import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {graphql} from 'gatsby';

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title={t('pageTitle')} bodyClassName='error-page' />
      <div className="flex flex-col items-center justify-center px-4 py-8 error-box">
        <h1 className="text-primary font-extrabold m-0">404</h1>
        <h2 className="tex-primary">{t('header')}</h2>
        <p className="text-center mt-8 md:mt-16 lg:mt-24">{t('lead')}</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "errorPage"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
